<template>
  <div class="c-app flex-row align-items-center" :class="{ 'c-dark-theme': $store.state.darkMode }">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="8">
          <CCardGroup>
            <CCard class="p-4">
              <CCardBody>
                <CForm>
                  <!-- <h1>Login</h1>
                  <p class="text-muted">Sign In to your account</p> -->
                  <CCol col="12" class="mb-5 px-4">
                    <img :src="require('@/assets/images/login/logo.png')" style="width: 100%" />
                  </CCol>
                  <CInput
                    placeholder="아이디 입력"
                    autocomplete="username email"
                    v-model="id"
                  >
                    <template #prepend-content><CIcon name="cil-user"/></template>
                  </CInput>
                  <CInput
                    placeholder="비밀번호 입력"
                    type="password"
                    autocomplete="curent-password"
                    v-model="password"
                  >
                    <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                  </CInput>
                  <CInput
                    placeholder="비밀번호 확인 입력"
                    type="password"
                    autocomplete="curent-password"
                    v-model="passwordConfirm"
                  >
                    <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                  </CInput>
                  <CInput
                    placeholder="휴대폰 입력"
                    v-model="phone"
                  >
                    <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                  </CInput>
                  <CInput
                    placeholder="닉네임 입력"
                    v-model="nickname"
                  >
                    <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                  </CInput>
                  <!-- <CInput
                    placeholder="프로필 이미지 등록"
                    v-model="profileImageURL"
                  >
                    <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                  </CInput> -->
                  <!-- <CInputFile
                    label="프로필 이미지"
                    horizontal
                    custom
                    ref="image"
                    @change="uploadImg()"
                  /> -->
                  <input ref="image" @change="uploadImg()" type="file" id="chooseFile" name="chooseFile" accept="image/*">

                  <img v-if="profileImageURL != ''" :src="`https://suriyo.api.togethersoft.co.kr/assets/${profileImageURL}`" style="width: 100%" />
                  <CInput
                    placeholder="회사명 입력"
                    v-model="companyName"
                  >
                    <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                  </CInput>
                  <CInput
                    placeholder="회사 주소 입력"
                    v-model="companyAddress"
                  >
                    <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                  </CInput>
                  <CInput
                    placeholder="회사 위도 입력"
                    v-model="companyLat"
                  >
                    <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                  </CInput>
                  <CInput
                    placeholder="회사 경도 입력"
                    v-model="companyLng"
                  >
                    <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                  </CInput>
                  <CInput
                    placeholder="회사 소개글 입력"
                    v-model="companyInformaion"
                  >
                    <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                  </CInput>
                  <CRow>
                    <CCol col="12" class="text-center">
                      <CButton color="primary" class="px-3" style="width: 100%"
                        @click="regist()"
                      >회원가입</CButton>
                    </CCol>
                    <!-- <CCol col="7" class="text-left">
                      <CButton color="link" class="px-0">회원가입</CButton>
                      <CButton color="link" class="d-lg-none">비밀번호 찾기</CButton>
                    </CCol>
                    <CCol col="5" class="text-right" style="display: flex; align-items: center; justify-content: flex-end;">
                      <CButton color="link" class="px-0">자동 로그인</CButton>
                      <input type="checkbox">
                    </CCol> -->
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
            <!-- <CCard
              color="primary"
              text-color="white"
              class="text-center py-5 d-md-down-none"
              body-wrapper
            >
              <CCardBody>
                <h2>Sign up</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                <CButton
                  color="light"
                  variant="outline"
                  size="lg"
                >
                  Register Now!
                </CButton>
              </CCardBody>
            </CCard> -->
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import VueUploadComponent from 'vue-upload-component'
// app.component('file-upload', VueUploadComponent)
import axios from 'axios';

export default {
  name: 'c_regist',
  components: {
    VueUploadComponent
  },
  data() {
    return {
      id: '',
      password: '',
      passwordConfirm: '',
      phone: '',
      nickname: '',
      profileImageURL: '',
      companyName: '',
      companyAddress: '',
      companyLat: '',
      companyLng: '',
      companyInformaion: '',

      image : ''
    }
  },
  beforeMount() {
    console.log(this.$route.params);
  },
  methods: {
    uploadImg() {
      console.log('들어왔다')
      var image = this.$refs['image'].files[0]

      const url = URL.createObjectURL(image)
      this.image = url
      console.log(url)
      console.log(this.image)

      // 이미지 업로드
      const func = async () => {
        const formData = new FormData();
        // formData.append("files", {
        //   type: 'image/jpeg',
        //   name: 'image.jpg',
        //   uri: url
        // });
        formData.append('files', image)
  
        try {
          const result_axios = await axios.post(`https://suriyo.api.togethersoft.co.kr/files`, formData,
          {
            headers: { 
              'content-type': 'multipart/form-data',
              // 'Cache-Control': 'no-cache',
              // 'Pragma': 'no-cache',
              // 'Expires': '0'
            },
          });
          if (result_axios.status == 200) {
            console.log('이미지 업로드 성공', result_axios)
            console.log(result_axios.data.data.id)
            // 서버에 업로드된 이미지의 id
            this.profileImageURL = result_axios.data.data.id;
          } else {
            console.log('이미지 업로드 실패', err);
          }
        } catch (err) {
          console.log(err);
        }
      }
      func();
    },
    regist() {
      console.log(this.id);
      console.log(this.password);
      console.log(this.passwordConfirm);
      console.log(this.phone);
      console.log(this.nickname);
      console.log(this.profileImageURL);
      console.log(this.companyName);
      console.log(this.companyAddress);
      console.log(this.companyLat);
      console.log(this.companyLng);
      console.log(this.companyInformaion);

      const asyncRegistIdCheck = async () => {
          console.log('회원가입간 유저 아이디 중복 체크 : ', this.id);
        
          try {
            const result = await axios.get('https://suriyo.api.togethersoft.co.kr/items/user?filter={"id":"' + this.id + '"}&meta=filter_count')
            console.log('결과값 : ', result.data);
        
            return result.data
          }
          catch(err) {
              console.log('query 오류 : ', err);
          }
      }

      const asyncRegist = async (regist_type) => {
          console.log('자체 회원가입 진행 : ', regist_type);
          // this.id
          // this.password
          // this.passwordConfirm
          // this.phone
          // this.nickname
          // this.profileImageURL
          // this.companyName
          // this.companyAddress
          // this.companyLat
          // this.companyLng
          // this.companyInformaion
        
          const data = {
              "id": this.id,
              "password": this.password,
              "regist_type": regist_type,
              "phone": this.phone,
              "nickname": this.nickname,
              "image": this.profileImageURL,
              "partner": true,
              "partner_index": [{
                "address": this.companyAddress,
                "company_name": this.companyName,
                "image": this.profileImageURL,
                "information": this.companyInformaion,
                "lat": this.companyLat,
                "lng": this.companyLng,
                "tel": this.phone,
              }]
          }

          try {
              const result = await axios.post('https://suriyo.api.togethersoft.co.kr/items/user', data)
              console.log('결과값 : ', result);
          
              return result
          }
          catch(err) {
              console.log('query 오류 : ', err);
          }
      }

      const func = async () => {
        // 필수값 체크
        if (this.id == '' || this.password == '' || this.passwordConfirm == '' || this.phone == '' || this.nickname == '' || this.profileImageURL == '' || this.companyName == '' || this.companyAddress == '' || this.companyLat == '' || this.companyLng == '' || this.companyInformaion == '') {
          alert('필수값을 입력해주세요');
        } else {
          // 비밀번호 일치여부 체크
          if (this.password != this.passwordConfirm) {
            alert('비밀번호가 일치하지 않습니다');
          } else {
            // 아이디 중복 여부 확인 필요
            const result_asyncRegistIdCheck = await asyncRegistIdCheck();
            if (result_asyncRegistIdCheck.meta.filter_count == 0) {
              // 동일한 아이디가 없을때
              // 조건 만족후 회원가입 진행
              const result_asyncRegist = await asyncRegist('local');
              // 회원가입 진행후 정상 등록되었을때
              if (result_asyncRegist.status == 200) {
                alert('회원가입이 완료되었습니다');
                setTimeout(() => {
                  this.$router.push({name: 'c_login'});
                }, 500);
              } else {
                alert('잠시후 다시 시도해주세요');
              }
            } else {
              alert('다른 아이디를 사용해주세요');
            }
          }
        }
      }
      func();
    }
  }
}
</script>
